import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/carousels/carousels.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import CarouselHeroComponent from "../../../library/components/carousel/react/_preview/CarouselHeroComponent"

// Preview
import CarouselControlsInsidePreview from "../../../library/components/carousel/react/_preview/controls-inside"
import CarouselControlsOutsidePreview from "../../../library/components/carousel/react/_preview/controls-outside"
import CarouselIndicatorsOutsidePreview from "../../../library/components/carousel/react/_preview/indicators-outside"
import CarouselIndicatorsInsidePreview from "../../../library/components/carousel/react/_preview/indicators-inside"
import CarouselIndicatorsControlsInsidePreview from "../../../library/components/carousel/react/_preview/indicators-controls-inside"
import CarouselCardPreview from "../../../library/components/carousel/react/_preview/card"
import CarouselTestimonialPreview from "../../../library/components/carousel/react/_preview/testimonial"
import CarouselLogoPreview from "../../../library/components/carousel/react/_preview/logo"

// All
const CarouselControlsInsideJsx = require("!!raw-loader!../../../library/components/carousel/react/controls-inside.jsx")
const CarouselControlsInsideHTML = require("!!raw-loader!../../../library/components/carousel/html/controls-inside.html")

const CarouselControlsOutsideJsx = require("!!raw-loader!../../../library/components/carousel/react/controls-outside.jsx")
const CarouselControlsOutsideHTML = require("!!raw-loader!../../../library/components/carousel/html/controls-outside.html")

const CarouselIndicatorsOutsideJsx = require("!!raw-loader!../../../library/components/carousel/react/indicators-outside.jsx")
const CarouselIndicatorsOutsideHTML = require("!!raw-loader!../../../library/components/carousel/html/indicators-outside.html")

const CarouselIndicatorsInsideJsx = require("!!raw-loader!../../../library/components/carousel/react/indicators-inside.jsx")
const CarouselIndicatorsInsideHTML = require("!!raw-loader!../../../library/components/carousel/html/indicators-inside.html")

const CarouselIndicatorsControlsInsideJsx = require("!!raw-loader!../../../library/components/carousel/react/indicators-controls-inside.jsx")
const CarouselIndicatorsControlsInsideHTML = require("!!raw-loader!../../../library/components/carousel/html/indicators-controls-inside.html")

const CarouselCardJsx = require("!!raw-loader!../../../library/components/carousel/react/card.jsx")
const CarouselCardHTML = require("!!raw-loader!../../../library/components/carousel/html/card.html")

const CarouselTestimonialJsx = require("!!raw-loader!../../../library/components/carousel/react/testimonial.jsx")
const CarouselTestimonialHTML = require("!!raw-loader!../../../library/components/carousel/html/testimonial.html")

const CarouselLogoJsx = require("!!raw-loader!../../../library/components/carousel/react/logo.jsx")
const CarouselLogoHTML = require("!!raw-loader!../../../library/components/carousel/html/logo.html")

export default function CarouselsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "CarouselControlsInside",
      title: "Controls inside ",
      active_tab: 1,
    },
    {
      component_name: "CarouselControlsOutside",
      title: "Controls outside",
      active_tab: 1,
    },
    {
      component_name: "CarouselIndicatorsOutside",
      title: "Indicator outside",
      active_tab: 1,
    },
    {
      component_name: "CarouselIndicatorsInside",
      title: "Indicator inside",
      active_tab: 1,
    },

    {
      component_name: "CarouselIndicatorsControlsInside",
      title: "Indicator & controls inside",
      active_tab: 1,
    },
    {
      component_name: "CarouselCard",
      title: "Carousel with cards",
      active_tab: 1,
    },
    {
      component_name: "CarouselTestimonial",
      title: "Testimonial Carousel",
      active_tab: 1,
    },
    {
      component_name: "CarouselLogo",
      title: "Logo Carousel",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Carousels - WindUI Component Library"
        ogtitle="Tailwind CSS Carousels - WindUI Component Library"
        description="A carousel component is a design element, that works like a slideshow, used to display images and videos on a website. Built with Tailwind CSS by WindUI."
        ogdescription="A carousel component is a design element, that works like a slideshow, used to display images and videos on a website. Built with Tailwind CSS by WindUI."
        ogimage={ogImage}
        url="components/carousels/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Carousels, glidejs, Carousels, Carousel Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Carousels</h1>
          <p>
            A carousel is a design element used to display images and videos on
            your website. It works like a slideshow, showing more than one image
            or video at a time. The content changes after a few seconds or when
            the visitor clicks to view the next one.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <CarouselHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="CarouselControlsInside">
            Carousel with controls inside{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselControlsInside"
            HtmlComponent={CarouselControlsInsideHTML.default}
            JsxComponent={CarouselControlsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselControlsInsidePreview
                CarouselControlsInside={
                  activeTabs[0].active_tab === 1
                    ? CarouselControlsInsideHTML.default
                    : CarouselControlsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselControlsOutside">
            Controls Outside <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselControlsOutside"
            HtmlComponent={CarouselControlsOutsideHTML.default}
            JsxComponent={CarouselControlsOutsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-16 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselControlsOutsidePreview
                CarouselControlsOutside={
                  activeTabs[1].active_tab === 1
                    ? CarouselControlsOutsideHTML.default
                    : CarouselControlsOutsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselIndicatorsOutside">
            Carousel with indicator outside{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselIndicatorsOutside"
            HtmlComponent={CarouselIndicatorsOutsideHTML.default}
            JsxComponent={CarouselIndicatorsOutsideJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselIndicatorsOutsidePreview
                CarouselIndicatorsOutside={
                  activeTabs[2].active_tab === 1
                    ? CarouselIndicatorsOutsideHTML.default
                    : CarouselIndicatorsOutsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselIndicatorsInside">
            Carousel with indicator inside{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselIndicatorsInside"
            HtmlComponent={CarouselIndicatorsInsideHTML.default}
            JsxComponent={CarouselIndicatorsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselIndicatorsInsidePreview
                CarouselIndicatorsInside={
                  activeTabs[3].active_tab === 1
                    ? CarouselIndicatorsInsideHTML.default
                    : CarouselIndicatorsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselIndicatorsControlsInside">
            Carousel with indicator & controls inside{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselIndicatorsControlsInside"
            HtmlComponent={CarouselIndicatorsControlsInsideHTML.default}
            JsxComponent={CarouselIndicatorsControlsInsideJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselIndicatorsControlsInsidePreview
                CarouselIndicatorsControlsInside={
                  activeTabs[4].active_tab === 1
                    ? CarouselIndicatorsControlsInsideHTML.default
                    : CarouselIndicatorsControlsInsideJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselCard">
            Carousel with cards{" "}
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselCard"
            HtmlComponent={CarouselCardHTML.default}
            JsxComponent={CarouselCardJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselCardPreview
                CarouselCard={
                  activeTabs[5].active_tab === 1
                    ? CarouselCardHTML.default
                    : CarouselCardJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselTestimonial">
            Testimonial Carousel
            <span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselTestimonial"
            HtmlComponent={CarouselTestimonialHTML.default}
            JsxComponent={CarouselTestimonialJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <CarouselTestimonialPreview
                CarouselTestimonial={
                  activeTabs[6].active_tab === 1
                    ? CarouselTestimonialHTML.default
                    : CarouselTestimonialJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CarouselLogo">
            Logo Carousel<span className="info-tip">Requires GlideJS</span>
          </h3>

          <PreviewBlock
            id="CarouselLogo"
            HtmlComponent={CarouselLogoHTML.default}
            JsxComponent={CarouselLogoJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-white p-16 pb-16 [&>*]:w-full">
              <CarouselLogoPreview
                CarouselLogo={
                  activeTabs[7].active_tab === 1
                    ? CarouselLogoHTML.default
                    : CarouselLogoJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use the carousel component to view multiple pieces of content that
            occupies a single coveted space:
          </p>
          <ul>
            <li>
              To enable more than one piece of content to occupy the same piece
              of real estate on the app interface.
            </li>
            <li>
              To display a group of content on the same level (set of images,
              users testimonials etc.)
            </li>
          </ul>
          <p>
            The carousels functionality is implemented using{" "}
            <a href="https://glidejs.com/" target="_blank" rel="external">
              GlideJS
            </a>
            . GlideJS CDN is used in all HTML files and the React components are
            using the{" "}
            <a
              href="https://github.com/glidejs/glide"
              target="_blank"
              rel="external"
            >
              @glidejs/glide
            </a>{" "}
            as a dependency.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>The carousel component consists of the following elements:</p>

          <ul>
            <li>The list of elements that will slide</li>
            <li>
              The navigation arrows (3rem (48px) in width and height) to provide
              enough space for reliable interaction
            </li>
            <li>
              The slide indicators (2.5rem(40px) touch area with 0.5rem(8px)
              dots) that displays the total number of slides and the active
              slide.
            </li>
          </ul>
          <p>
            Both indicators and navigation arrows can be placed within or
            outside the slide area.
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            All images should use the <code>alt</code> to be able to be
            accessible from screen readers.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
